<template>
  <div class="dynamic">
    <div class="banner">
      <div class="content">
        <div class="title" data-aos="fade-up">{{$t('dynamic.bannerText')}}</div>
      </div>
    </div>
    <div class="bannerMb">
      <div class="content">
        <div class="title" data-aos="fade-up">{{$t('dynamic.bannerText')}}</div>
      </div>
    </div>
    <div class="conentBox">
      <img class="w1" src="@/assets/w1.png" alt="">
      <img class="w2" v-if="dynamicsdata.length > 5" src="@/assets/w2.png" alt="">
      <img class="w3" v-if="dynamicsdata.length > 5" src="@/assets/w3.png" alt="">
      <img class="w4" v-if="dynamicsdata.length > 5" src="@/assets/w4.png" alt="">
      
      <div class="content">
        <div class="list" v-for="(item,index) in dynamicsdata" :key="index" @click="dynamicDetalis(item.id)" :data-aos="index%2 == 0?'fade-right':'fade-left'">
          <div class="imgBox" v-if="index%2 == 0" >
            <img class="img" :src="baseHostUrl + item.imgurl" alt="">
          </div>
          <div class="info">
            <div class="title">{{item.title}}</div>
            <div class="time">{{item.happen_times}}</div>
            <div class="text">{{item.description}}</div>
            
          </div>
          <div class="imgBox" v-if="index%2 == 1" >
            <img class="img" :src="baseHostUrl + item.imgurl" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="conentBoxMb">

      <div class="conent">
        <div class="list" v-for="(item,index) in dynamicsdata" :key="index" @click="dynamicDetalis(item.id)" data-aos="fade-up">
          <div class="imgBox"  >
            <img class="img" :src="baseHostUrl + item.imgurl" alt="">
          </div>
          <div class="info">
            <div class="title">{{item.title}}</div>
            <div class="time">{{item.happen_times}}</div>
            <div class="text" >{{item.description}}</div>
          </div>

        </div>
      </div>
    </div>
    <div class="more" v-if="!noData" @click="onMoreData()">
      <div class="text">查看所有公司动态</div>
      <img class="img" src="@/assets/icon_down.png" alt="">
    </div>
    <div class="noData" v-if="noData">没有更多数据了</div>
  </div>
</template>
<script>
import {baseHostUrl,getdynamicsdata} from '@/utils/index.js'
export default {
  name: 'dynamic',
  components: {

  },
  data () {
    return {
      pcOrmb: true,
      page: 1,
      dynamicsdata: [],
      baseHostUrl: '',
      count: 0,
      noData: false
    }
  },
  computed: {
  },
  created() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {  
      this.pcOrmb = false
    }else{  
      this.pcOrmb = true
    } 
  },
  mounted() {
    this.getdynamicsdata()
    this.baseHostUrl = baseHostUrl()
  },
  methods: {
    dynamicDetalis(id) {
      this.$router.push('/dynamicDetalis?id=' + id)
      // this.$router.push(
      //   { 
      //     name: 'dynamicDetalis', 
      //     params: { 
      //       id: id
      //     } 
      //   }
      // )
    },
    async getdynamicsdata() {
      let data = {
        type: localStorage.getItem('language').toLowerCase(),
        page: this.page,
        limit: 10
      }
      
      let res = await getdynamicsdata(data)
      this.dynamicsdata = this.dynamicsdata.concat(res.data.list)
      this.count = res.data.count
      if (res.data.list.length < 10){
        this.noData = true
      }
    },
    onMoreData() {
      if (this.count > this.dynamicsdata.length) {
        this.page++
        this.getdynamicsdata()
      } else {
        this.noData = true
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.dynamic{
  background: #F6F7F9;
}
.banner{
  background: url('../assets/banner(1).png') no-repeat;
  height: 31vw;
  background-size: cover;
  display: flex;
  align-items: center;
  .content{
    width: 63vw;
    margin: auto;
    .title{
      font-size: 1.8vw;
      font-weight: 700;
      color: #fff;
      text-align: left;
    }
  }
}
.bannerMb{
  display: none;
}
.content{
  width: 63vw;
  margin: auto;
  padding: 1vw 0 3vw 0;
  position: relative;
  .list{
    display: flex;
    padding: 3vw 0;
    border-bottom: solid 1px #E9E9E9;
    cursor: pointer;
    &:last-child{
      border-bottom: none;
    }
    .imgBox{
      margin: 0 1vw;
      width: 24vw;
      flex: 0 0 24vw;
      height: 14vw;
      overflow: hidden;
      .img{
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-out;
      }
      &:hover{
        .img{
          transform: scale(1.1);
          transition: all 0.3s ease-out;
        }
      }
    }
    .info{
      padding: 0 1vw;
      flex: 1;
      .title{
        font-size: 1.2vw;
        color: #333333;
      }
      .time{
        font-size: 0.8vw;
        margin: 0.8vw 0 1.2vw 0;
        color: #999999;
      }
      .text{
        font-size: 0.85vw;
        color: #666666;
        line-height: 1.6;
      }
    }
  }
}
.conentBoxMb{
  display: none;
}
.more{
  text-align: center;
  font-size: 0.6vw;
  padding-bottom: 3vw;
  cursor: pointer;
  .img{
    margin: 1vw auto auto auto;
  }
}
.conentBox{
  position: relative;
}
.w1{
  position: absolute;
  top: 0;
  left: 0;
  width: 20vw;
}
.w2{
  position: absolute;
  top: 30%;
  right: 0;
  width: 20vw;
}
.w3{
  position: absolute;
  top: 60%;
  left: 0;
  width: 20vw;
}
.w4{
  position: absolute;
  bottom:0;
  right: 0;
  width: 20vw;
}
@media screen and (max-width:750px){
  .banner{
    display: none;
  }
  .bannerMb{
    background: url('../assets/banner(1)mb.jpg') no-repeat;
    height: 106vw;
    background-size: cover;
    display: flex;
    align-items: center;
    .content{
      padding: 6vw;
      flex: 1;
      .title{
        font-size: 7vw;
        font-weight: 700;
        color: #fff;
        text-align: left;
      }
    }
  }
  .conentBox{
    display: none;
  }
  .conentBoxMb{
    display: block;
    padding: 0 5vw;
    .conent{
      margin: auto;
      padding: 4vw 0 3vw 0;
      position: relative;
      .list{
        padding: 7vw 0;
        border-bottom: solid 1px #E9E9E9;
        cursor: pointer;
        &:last-child{
          border-bottom: none;
        }
        .imgBox{
          padding: 0 1vw;
          .img{
            width: 100%;
            transition: all 0.3s ease-out;
          }
          &:hover{
            .img{
              transform: scale(1.1);
              transition: all 0.3s ease-out;
            }
          }
        }
        .info{
          padding: 0 1vw;
          .title{
            font-size: 5.5vw;
            color: #333333;
            margin-top: 4vw;
          }
          .time{
            font-size: 3vw;
            margin: 3vw 0;
            color: #999999;
          }
          .text{
            font-size: 4vw;
            color: #666666;
            line-height: 2;
          }
        }
      }
    }
  }
  .w1{
    position: absolute;
    top: 0;
    left: 0;
    width: 60vw;
  }
  .w2{
    position: absolute;
    top: 30%;
    right: 0;
    width: 60vw;
  }
  .w3{
    position: absolute;
    top: 60%;
    left: 0;
    width: 60vw;
  }
  .w4{
    position: absolute;
    bottom:0;
    right: 0;
    width: 60vw;
  }
  .more{
    text-align: center;
    font-size: 12px;
    padding-bottom: 3vw;
    cursor: pointer;
    .img{
      margin: 1vw auto auto auto;
    }
  }
}
.noData{
    text-align: center;
    font-size: 12px;
    padding-bottom: 3vw;
}
</style>